<template>
  <DynamicForm
    ref="form"
    class="MdfeDetails__Form__Fields"
    :style="style"
    :model="form"
    :blueprint="blueprint({optionsFields})"
    :endpoint-errors="endpointErrors"
    submit-type="json"
    @generate="handlerGenerate"
    @submit="handlerSubmit"
    @cancel="handlerCancel"
  />
</template>
<script>
import { mapActions } from 'vuex'
import { ctes } from '@/api'
import blueprint from './MdfeDetailsForm.blueprint'
import { populateForm } from '../../utils/utils.js'
import { DynamicForm } from '@/components/molecules'

export default {
  name: 'MdfeDetailsForm',
  components: {
    DynamicForm,
  },
  data() {
    return {
      form: {
        //Details
        id_name: null,
        status: null,
        //MDFe
        vehicle_tara: null,
        vehicle_kg: null,
        vehicle_type: null,
        vehicle_body: null,
        eminent: null,
        //Insurance
        insurance_responsible: null,
        insurance_responsible_cnpj: null,
        insurance_company_name: null,
        insurance_company_cnpj: null,
        policy_number: null,
        registration_number: null,
      },

      form_initial: {},
      endpointErrors: {},
    }
  },
  computed: {
    //Route
    routeParams() {
      return {
        mdfeId: this.$route.params.id,
      }
    },

    //Options
    optionsFields() {
      return {
        vehicleType: [
          {id: 0, name: 'VAN'},
          {id: 1, name: 'Vehicle 001'},
          {id: 2, name: 'Vehicle 002'},
          {id: 3, name: 'Vehicle 003'},
          {id: 4, name: 'Vehicle 004'},
          {id: 5, name: 'Vehicle 005'},
        ],
        vehicleBody: [
          {id: 0, name: 'Não Aplicável'},
          {id: 1, name: 'Vehicle 001'},
          {id: 2, name: 'Vehicle 002'},
          {id: 3, name: 'Vehicle 003'},
          {id: 4, name: 'Vehicle 004'},
          {id: 5, name: 'Vehicle 005'},
        ],
        eminent: [
          {id: 0, name: 'Prestador de serviço de transporte'},
          {id: 1, name: 'Eminent 001'},
          {id: 2, name: 'Eminent 002'},
          {id: 3, name: 'Eminent 003'},
          {id: 4, name: 'Eminent 004'},
          {id: 5, name: 'Eminent 005'},
        ],
        insuranceResponsible: [
          {id: 0, name: 'Emitente do MDF-e'},
          {id: 1, name: 'Emitente 001'},
          {id: 2, name: 'Emitente 002'},
          {id: 3, name: 'Emitente 003'},
          {id: 4, name: 'Emitente 004'},
          {id: 5, name: 'Emitente 005'},
        ]
      }
    },

    //Styles
    style: function() {
      return {
        '--grid-template':  this.formGridTemplateStyle,
      }
    },

    formGridTemplateStyle() {
      const styleMdfeDetails = `'labelRouteDetails labelRouteDetails labelRouteDetails . . . . . . . . .'
        'id_name id_name id_name status status status . . . . . .'`
      const styleMdfeDetailsEdit = `'labelMdfeDetails labelMdfeDetails labelMdfeDetails . . . . . . . . .'
        'vehicle_tara vehicle_tara vehicle_tara vehicle_kg vehicle_kg vehicle_kg vehicle_type vehicle_type vehicle_type vehicle_body vehicle_body vehicle_body'
        'eminent eminent eminent . . . . . . . . .'
        'labelMdfeInsurance labelMdfeInsurance . . . . . . . . . .'
        'insurance_responsible insurance_responsible insurance_responsible insurance_responsible_cnpj insurance_responsible_cnpj insurance_responsible_cnpj insurance_company_name insurance_company_name insurance_company_name insurance_company_cnpj insurance_company_cnpj insurance_company_cnpj'
        'policy_number policy_number policy_number registration_number registration_number registration_number . . . . . .'`
      const styleActions = '". . . . . . . . . generate cancel confirm"'

      const style = (
        styleMdfeDetails +
        styleMdfeDetailsEdit + 
        styleActions
      )

      return style
    }

  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
    }),

    //Form
    blueprint,

    //Init
    async init() {
      if(this.routeParams.mdfeId) await this.fetchMdfe()
      this.getInitialForm()
    },
    
    //Calls
    async fetchMdfe() {
      this.setLoading(true)
      await ctes.fetchMdfe(
        { id: this.routeParams.mdfeId },
        (res) => {
          populateForm(res, this.form, this.form_initial)
        },
        (error) => error,
        () => this.setLoading(false)
      )
    },

    //Actions
    async putMdfeDetails() {
      this.setLoading(true)
      await ctes.putMdfeDetails(
        { id: this.routeParams.mdfeId, body: this.form },
        () => {
          this.$router.push('/routes/management?tab=mdfe')
        },
        (error) => this.endpointErrors = error.validation,
        () => this.setLoading(false)
      )
    },

    //Handlers
    handlerCancel() {
      this.$router.push('/routes/management?tab=mdfe')
    },

    handlerSubmit() {
      this.$emit('save')
    },

    async handlerGenerate() {
      this.setLoading(true)
      await ctes.generateMdfe(
        { id: this.routeParams.mdfeId },
        (res) => res,
        (error) => error,
        () => this.setLoading(false)
      )
    },

    //Helpers
    getInitialForm() {
      this.form_initial = Object.assign({}, this.form)
    },

    setInitialForm() {
      this.form = Object.assign({}, this.form_initial)
    },

  }
}
</script>
<style lang="scss" scoped>
.MdfeDetails {
  &__Form {
    &__Fields {
      grid-template-areas: var(--grid-template);
      margin-bottom: 30px;

      /deep/ .BaseButton {
        .BaseButton__Text {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
