export default ({
  optionsFields
}) => {
  const mdfeDetails = [
    {
      id: 'labelRouteDetails',
      tag: 'label',
      content: 'Detalhes da Rota'
    },
    {
      id: 'id_name',
      model: 'id_name',
      tag: 'InputGroup',
      props: {
        label: 'ID da MDFe',
        placeholder: 'Nome',
        readOnly: true,
      }
    },
    {
      id: 'status',
      model: 'status',
      tag: 'InputGroup',
      props: {
        label: 'Status',
        placeholder: 'Status',
        readOnly: true,
      }
    }
  ]

  const mdfeDetailsInfo = [
    {
      id: 'labelMdfeDetails',
      tag: 'label',
      content: 'Informações da MDFe'
    },
    {
      id: 'vehicle_tara',
      model: 'vehicle_tara',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Tara Veículo',
        placeholder: 'Tara Veículo',
        invalidLabel: 'Campo obrigatório',
      }
    },
    {
      id: 'vehicle_kg',
      model: 'vehicle_kg',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Capacidade KG do Veículo',
        placeholder: 'KG do Veículo',
        invalidLabel: 'Campo obrigatório',
      }
    },
    {
      id: 'vehicle_type',
      model: 'vehicle_type',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Tipo rodado do Veículo',
        label: 'Tipo rodado do Veículo',
        placeholder: 'Selecione',
        selectOptions: optionsFields.vehicleType,
        invalidLabel: 'Campo obrigatório',
      },
    },
    {
      id: 'vehicle_body',
      model: 'vehicle_body',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Tipo carroceria do Veículo',
        label: 'Tipo carroceria do Veículo',
        placeholder: 'Selecione',
        selectOptions: optionsFields.vehicleBody,
        invalidLabel: 'Campo obrigatório',
      },
    },
    {
      id: 'eminent',
      model: 'eminent',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Eminente',
        label: 'Eminente',
        placeholder: 'Selecione',
        selectOptions: optionsFields.eminent,
        invalidLabel: 'Campo obrigatório',
      },
    },
  ]

  const mdfeDetailsInsurance = [
    {
      id: 'labelMdfeInsurance',
      tag: 'label',
      content: 'Seguros Carga'
    },
    {
      id: 'insurance_responsible',
      model: 'insurance_responsible',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Responsável do Seguro',
        label: 'Responsável do Seguro',
        placeholder: 'Selecione',
        selectOptions: optionsFields.insuranceResponsible,
        invalidLabel: 'Campo obrigatório',
      },
    },
    {
      id: 'insurance_responsible_cnpj',
      model: 'insurance_responsible_cnpj',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'CNPJ do Responsável',
        placeholder: 'CNPJ do Responsável',
        mask: 'cnpj',
        invalidLabel: 'Campo obrigatório',
      }
    },
    {
      id: 'insurance_company_name',
      model: 'insurance_company_name',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Nome da Seguradora',
        placeholder: 'Nome da Seguradora',
        invalidLabel: 'Campo obrigatório',
      }
    },
    {
      id: 'insurance_company_cnpj',
      model: 'insurance_company_cnpj',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'CNPJ da Seguradora',
        placeholder: 'CNPJ da Seguradora',
        invalidLabel: 'Campo obrigatório',
        mask: 'cnpj',
      }
    },
    {
      id: 'policy_number',
      model: 'policy_number',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Número da Apólice',
        placeholder: 'Número da Apólice',
        invalidLabel: 'Campo obrigatório',
      }
    },
    {
      id: 'registration_number',
      model: 'registration_number',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Número da Averbação',
        placeholder: 'Número da Averbação',
        invalidLabel: 'Campo obrigatório',
      }
    },
  ]

  const actions = [
    {
      id: 'generate',
      tag: 'BaseButton',
      props: {
        label: 'Gerar MDFe',
        color: 'black',
        textColor: 'white',
        filled: true,
        type: 'button',
      },
      events: {
        click: 'generate',
      },
    },
    {
      id: 'cancel',
      tag: 'BaseButton',
      props: {
        label: 'Cancelar',
        color: 'black',
        textColor: 'white',
        filled: true,
        type: 'button',
      },
      events: {
        click: 'cancel',
      },
    },
    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: 'Salvar',
        color: 'black',
        textColor: 'white',
        filled: true,
        type: 'submit',
      },
    },
  ]

  return [
    ...mdfeDetails,
    ...mdfeDetailsInfo,
    ...mdfeDetailsInsurance,
    ...actions
  ]
}